import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from '../pages/auths/Login';
import UserDashboard from '../pages/userdashbord/UserDasboard';
import NotFound from '../pages/Notfound';
import PrivateRoute from './components/PrivateRoute';
import Sidebar from '../components/sidebar/Sidebar';
import ChatWindow from '../pages/chatpage/chatwindows';
import UserDashboardLayout from '../components/Layouts/UserDashboard';
import AdminList from '../pages/users/admin/Index';
import MyProfile from '../pages/users/myProfile';
import RecoverPassword from '../pages/recoverPassword/RecoverPassword';
import ConfirmRecover from '../pages/recoverPassword/Confirm';
import DashStat from '../pages/dashboard/Index';

const AppRoutes = () => {
  return (
    <Router>

        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="password-reset" element={<RecoverPassword/>} />
          <Route path="password-reset/confirm/:uuid/:token" element={<ConfirmRecover/>} />
          <Route element={<UserDashboardLayout/>}>
                  <Route index path="/" element={<ChatWindow />} />
                  <Route path="/chat" element={<ChatWindow />} />
                  <Route path="/chat/:chatId" element={<ChatWindow/>} />
                  <Route path="/dashboard" element={<DashStat />} />
                  <Route path="/admin" element={<UserDashboard/>} />
                  <Route path="/my/profil" element={<MyProfile/>}/>

          </Route>

            {/* Route pour gérer les pages non trouvées */}
          <Route path="*" element={<NotFound />} />
        </Routes>
    </Router>
  );
};

export default AppRoutes;
