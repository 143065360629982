import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { API } from '../../services/axios';
import { URLS } from '../../services/urls';
import { loginSuccess } from '../../store/slices/auth';
import { getUserDetails, setUserDetails } from '../../components/utils/functions';
import 'bootstrap/dist/css/bootstrap.min.css';
import Preloader from '../../components/preloader/Preloader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false); // Nouvel état pour gérer la visibilité du mot de passe


  const onSubmit = async (data) => {
    setLoading(true);
    //console.log("Données soumises:", data); // Affiche les données soumises.

    try {
      const response = await API.post(URLS.AUTHS.LOGIN, data);
      if (response.status === 200) {
        const { access, refresh, user } = response.data;

        // Créer un objet utilisateur pour stocker les détails
        const userDetails = {
          access_token: access,
          refresh_token: refresh,
          id: user.id,
          email: user.email,
          first_name: user.first_name,
          role: user.role,
          service: user.service,
        };

        // Stocker dans localStorage
        //console.log(response);
        setUserDetails(userDetails);
        getUserDetails(userDetails);

        // Stocker dans Redux
        dispatch(loginSuccess(userDetails));

        toast.success("Login successful!");

        const redirectUrl = searchParams.get("redirect") || "/";
        navigate(redirectUrl, { replace: true });
      } else {
        throw new Error("Login failed");
      }
    } catch (error) {
      toast.error("Invalid credentials, please try again.");
    } finally {
      setLoading(false);
    }

  };

  return (
    <>
    <div className="container-fluid login">
      <form onSubmit={handleSubmit(onSubmit)} className="login-form">
        <img 
          src="/assets/img/logo/goliatCont.webp" 
          className="logoG" 
          width="70px" 
          height="70px" 
          alt="Logo de Goliat" 
        />
        <h4 className="text-center titlelogin">
          Bienvenue, veuillez vous connecter
        </h4>
        
        {loading && (
          <div className="preloader-wrapper">
            <Preloader />
          </div>
        )}

        <div className="mb-3 mt-5">
          <input 
            type="email" 
            className="form-control emailogin" 
            placeholder="Entrez votre Email" 
            required 
            {...register("email", { required: "Email requis" })} 
          />
          {errors.email && <p className="error">{errors.email.message}</p>}
        </div>

        <div className="mb-3 position-relative">
          <input 
            type={passwordVisible ? "text" : "password"} 
            className="form-control passwordlogin"
            placeholder="Entrez votre mot de passe"
            {...register("password", { required: "Mot de passe requis" })}
          />
          {errors.password && <p className="error">{errors.password.message}</p>}

          <span 
            className="password-toggle-icon" 
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
          </span>

          <p className="form-text mt-3">
            Mot de passe oublié ?  
            <Link className="text-muted" to="/password-reset"> Réinitialiser</Link>
          </p>
        </div>

        <button type="submit" className="loginbtn">Connexion</button>

        <button type="button" className="google">
          <img src="/assets/img/google-logo-NePEveMl.svg" className="logogoogle" alt="google"/>
          <a href="/" className="textbtn">Continuez avec Google</a>
        </button>
      </form>
    </div>
 
    </>
  );
};

export default Login;