import React, { useState } from 'react';
import AdminList from '../users/admin/Index';
import { isAdmin, isManager } from '../../components/utils/functions';
import Quickmessage from '../chatpage/Quickmessage';
import { Link } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import { logout } from '../../services/logout';
import { useTranslation } from "react-i18next";
import Navbar from '../../components/navbar/Navbar';


const UserDashboard = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();




  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <div className='dashboard-container-global scroll-container'>
        <div className="chat-header">
         <Navbar/>
    </div>
      {(isAdmin() || isManager()) && (
            <AdminList />
        )}
      </div>
    </>
  );
};

export default UserDashboard;
