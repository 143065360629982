import { useEffect, useState } from "react";
import { FaPaperclip, FaSignOutAlt } from "react-icons/fa";
import { logout } from "../../services/logout";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isAdmin, isManager } from '../../components/utils/functions';


const Navbar = () => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);



  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div> <div className="chat-header">
          <div className="profile-dropdown">
            <img 
              src={"/assets/img/logo_goliat.png"} 
              alt="User Profile" 
              className="profile-image" 
              onClick={toggleDropdown} 
            />
            {isDropdownOpen && (
              <div className="dropdown-menu">
                <Link className='dropdown-item' to='/my/profil'>Mon profil</Link>
                <Link className='dropdown-item' to='/dashboard'>Tableau de bord</Link>
                {(isAdmin() || isManager()) && (
                    <Link className='dropdown-item' to='/admin'>Administration</Link>
                )}
                
                <Link className='dropdown-item text-danger' to="#" onClick={() => logout(t)}>Déconnecter <FaSignOutAlt size={20} style={{ marginRight: '8px' }}/></Link>
              </div>
            )}
          </div>
        </div>
      </div>
  )
}

export default Navbar