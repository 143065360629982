import React, { useState, useEffect } from 'react';
import { API } from '../../services/axios';
import { URLS } from '../../services/urls';
// Import des composants pour le graphique
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Navbar from '../../components/navbar/Navbar';

// Enregistrement des modules Chart.js utilisés
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DashStat = () => {
  // État de bascule de la sidebar (si nécessaire)
  const [collapsed, setCollapsed] = useState(false);
  const handleToggleSidebar = () => setCollapsed(prev => !prev);

  // États pour le filtrage par date
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // État pour stocker les statistiques récupérées depuis l'API  
  // (l'API renvoie les clés : total_user_messages, total_chats, total_responses, total_generated_images, evolution_stats)
  const [stats, setStats] = useState({
    total_user_messages: 0,
    total_chats: 0,
    total_responses: 0,
    total_generated_images: 0,
    evolution_stats: [], // Tableau d'objets { date, count } pour l'évolution des réponses
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fonction de récupération des statistiques avec filtrage optionnel par date
  const fetchStats = async () => {
    setLoading(true);
    try {
      let url = `${URLS.BASE_URL}${URLS.STATISTIQUES.user}`;
      // Si une plage de dates est définie, on ajoute des paramètres query
      if (startDate && endDate) {
        url += `?start_date=${startDate}&end_date=${endDate}`;
      }
      const response = await API.get(url);
      setStats(response.data);
    } catch (err) {
      console.error('Erreur lors de la récupération des statistiques:', err);
      setError('Erreur lors de la récupération des statistiques.');
    } finally {
      setLoading(false);
    }
  };

  // Récupération initiale lors du montage du composant
  useEffect(() => {
    fetchStats();
  }, []);

  // Handler pour appliquer le filtre de date
  const handleFilter = (e) => {
    e.preventDefault();
    fetchStats();
  };

  // Préparation des données pour le graphique d'évolution des réponses
  const responsesChartData = {
    labels: stats.evolution_stats?.map(item => item.date) || [],
    datasets: [
      {
        label: 'Réponses générées',
        data: stats.evolution_stats?.map(item => item.count) || [],
        backgroundColor: 'rgba(77, 75, 192, 0.6)',
      },
    ],
  };

  const responsesChartOptions = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: {
        display: true,
        text: 'Evolution des réponses générées',
      },
    },
  };

  // Pour le graphique comparatif, on utilise :
  // - Pour "Réponses générées" : la donnée evolution_stats
  // - Pour "Requêtes utilisateur" : si l'API ne renvoie pas de donnée spécifique, on remplit avec des zéros.
  const userMessagesData =
    (stats.evolution_user_messages && stats.evolution_user_messages.length > 0)
      ? stats.evolution_user_messages.map(item => item.count)
      : stats.evolution_stats?.map(() => 0); // Remplit avec 0 pour chaque date

  const comparisonChartData = {
    labels: stats.evolution_stats?.map(item => item.date) || [],
    datasets: [
      {
        label: 'Requêtes utilisateur',
        data: userMessagesData || [],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
      {
        label: 'Réponses générées',
        data: stats.evolution_stats?.map(item => item.count) || [],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const comparisonChartOptions = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: {
        display: true,
        text: 'Comparaison des requêtes utilisateur et des réponses',
      },
    },
  };

  if (loading) {
    return <div>Chargement des statistiques...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
    <div className="dashboard-content-stat">
      <header className="dashboard-header">
        <h1>Tableau de bord</h1>
      </header>

      {/* Formulaire de filtre de dates */}
      <form className="date-filter" onSubmit={handleFilter}>
        <label>
          Date de début:
          <input 
            type="date" 
            value={startDate} 
            onChange={(e) => setStartDate(e.target.value)} 
          />
        </label>
        <label>
          Date de fin:
          <input 
            type="date" 
            value={endDate} 
            onChange={(e) => setEndDate(e.target.value)} 
          />
        </label>
        <button type="submit">Filtrer</button>
      </form>

      {/* Section des widgets */}
      <section className="widgets">
        <div className="widget">
          <h3>Messages envoyés</h3>
          <p>{stats.total_user_messages}</p>
        </div>
        <div className="widget">
          <h3>Discussions</h3>
          <p>{stats.total_chats}</p>
        </div>
        <div className="widget">
          <h3>Réponses générées</h3>
          <p>{stats.total_responses}</p>
        </div>
        <div className="widget">
          <h3>Images générées</h3>
          <p>{stats.total_generated_images}</p>
        </div>
      </section>

      {/* Graphique d'évolution des réponses */}
      <section className="stats">
        <h2>Evolution des réponses</h2>
        {stats.evolution_stats && stats.evolution_stats.length > 0 ? (
          <Bar data={responsesChartData} options={responsesChartOptions} />
        ) : (
          <p>Aucune donnée d'évolution disponible.</p>
        )}
      </section>

      {/* Graphique comparatif requêtes vs réponses */}
      <section className="stats">
        <h2>Comparaison requêtes vs réponses</h2>
        {stats.evolution_stats && stats.evolution_stats.length > 0 ? (
          <Bar data={comparisonChartData} options={comparisonChartOptions} />
        ) : (
          <p>Aucune donnée de comparaison disponible.</p>
        )}
      </section>
    </div>
    <Navbar/>
    </>
  );
  
};

export default DashStat;
