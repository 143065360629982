import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { API } from '../../services/axios';
import { URLS } from '../../services/urls';
import { toast } from 'react-toastify';
import Preloader from '../../components/preloader/Preloader';
import { FaPaperPlane, FaTimes, FaPaperclip, FaDownload, FaEdit } from 'react-icons/fa';
import { getUserDetails } from '../../components/utils/functions';
import { useTranslation } from 'react-i18next';
import Navbar from '../../components/navbar/Navbar';
import DOMPurify from 'dompurify';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const FormattedMessage = React.memo(({ content }) => {
  const codeBlockRegex = /```(\w+)?\n([\s\S]*?)```/g;
  let lastIndex = 0;
  const parts = [];
  let match;
  while ((match = codeBlockRegex.exec(content)) !== null) {
    const [fullMatch, language, code] = match;
    const index = match.index;
    if (index > lastIndex) {
      parts.push({
        type: 'text',
        content: content.slice(lastIndex, index)
      });
    }
    parts.push({
      type: 'code',
      language: language || 'javascript',
      content: code
    });
    lastIndex = index + fullMatch.length;
  }
  if (lastIndex < content.length) {
    parts.push({
      type: 'text',
      content: content.slice(lastIndex)
    });
  }
  return (
    <div>
      {parts.map((part, idx) =>
        part.type === 'code' ? (
          <SyntaxHighlighter key={idx} language={part.language} style={darcula}>
            {part.content}
          </SyntaxHighlighter>
        ) : (
          <div key={idx} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(part.content) }} />
        )
      )}
    </div>
  );
});

const UserMessage = React.memo(({ content }) => (
  <div className="user-message">
    <p>{content}</p>
  </div>
));

const ImageMessage = React.memo(({ content, index }) => {
  const url = content.split('Image URL: ')[1].split('\n')[0];
  const description = content.includes('Description:')
    ? content.split('Description:')[1].trim()
    : '';
  return (
    <div className="image-message">
      <img src={url} alt="Image générée par l'IA" className="chat-image-assist" />
      <a href={url} download={`image_${index}.png`} className="download-link">
        <FaDownload className="download-icon" />
      </a>
      {description && <p className="image-description">{description}</p>}
    </div>
  );
});

const ChatWindow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { chatId } = useParams();

  const [finalMessages, setFinalMessages] = useState([]);
  const [assistantStreamingContent, setAssistantStreamingContent] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [csvFiles, setCsvFiles] = useState([]);
  const [excelFiles, setExcelFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [user, setUser] = useState(null);
  const [regenerateVisible, setRegenerateVisible] = useState(false);
  const [lastUserMessage, setLastUserMessage] = useState('');

  const fileInputRef = useRef(null);

  // Bouton pour créer une nouvelle discussion
  const handleNewChat = () => {
    localStorage.removeItem('chatId');
    navigate('/chat'); // L'URL sera exactement "/chat"
  };

  // Récupération des détails utilisateur
  useEffect(() => {
    const userDetails = getUserDetails();
    if (userDetails) setUser(userDetails);
  }, []);

  // Charger les messages si chatId est présent
  useEffect(() => {
    if (chatId) {
      const fetchMessages = async () => {
        try {
          const response = await API.get(
            `${URLS.BASE_URL}${URLS.CHATGPT.chat.details.replace('<int:chat_id>', chatId)}`
          );
          if (Array.isArray(response.data)) setFinalMessages(response.data);
        } catch (error) {
          console.error("Erreur lors de la récupération des messages :", error);
          toast.error("Erreur lors de la récupération des messages.");
        }
      };
      fetchMessages();
    } else {
      setFinalMessages([]);
    }
  }, [chatId]);

  // Redirection automatique : uniquement si l'URL n'est pas exactement "/chat"
  useEffect(() => {
    if (!chatId && location.pathname !== '/chat') {
      const storedChatId = localStorage.getItem('chatId');
      if (storedChatId) navigate(`/chat/${storedChatId}`);
    }
  }, [chatId, navigate, location.pathname]);

  // Gestion des aperçus de fichiers
  useEffect(() => {
    const imagePreviews = images.map(image => URL.createObjectURL(image));
    const pdfPreviews = pdfFiles.map(pdf => pdf.name);
    const csvPreviews = csvFiles.map(csv => csv.name);
    const excelPreviews = excelFiles.map(excel => excel.name);
    setPreviews([...imagePreviews, ...pdfPreviews, ...csvPreviews, ...excelPreviews]);
    return () => {
      imagePreviews.forEach(url => URL.revokeObjectURL(url));
    };
  }, [images, pdfFiles, csvFiles, excelFiles]);

  // Nettoyage du streaming une fois le message complet ajouté
  useEffect(() => {
    if (
      finalMessages.length > 0 &&
      finalMessages[finalMessages.length - 1].role === 'assistant'
    ) {
      setAssistantStreamingContent('');
      setIsStreaming(false);
    }
  }, [finalMessages]);

  // Scroll automatique vers le bas
  useEffect(() => {
    const messagesList = document.querySelector('.messages-list');
    if (messagesList) messagesList.scrollTop = messagesList.scrollHeight;
  }, [finalMessages, assistantStreamingContent]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (
      !newMessage.trim() &&
      images.length === 0 &&
      pdfFiles.length === 0 &&
      csvFiles.length === 0 &&
      excelFiles.length === 0
    ) {
      toast.error("Veuillez taper un message ou ajouter des fichiers avant d'envoyer.");
      return;
    }
  
    setLastUserMessage(newMessage);
    const userMessage = {
      id: Date.now(),
      role: 'user',
      type: 'text',
      content: newMessage,
    };
    setFinalMessages(prev => [...prev, userMessage]);
  
    // Réinitialiser le streaming
    setAssistantStreamingContent('');
    setIsStreaming(false);
  
    const formData = new FormData();
    formData.append('role', 'user');
    formData.append('content', newMessage);
    images.forEach(image => formData.append('image', image));
    pdfFiles.forEach(pdf => formData.append('pdf', pdf));
    csvFiles.forEach(csv => formData.append('csv', csv));
    excelFiles.forEach(excel => formData.append('excel', excel));
  
    setLoading(true);
    setRegenerateVisible(false);
  
    const currentChatId = chatId || localStorage.getItem('chatId');
    let url = currentChatId
      ? `${URLS.BASE_URL}${URLS.CHATGPT.chat.update.replace('<int:chat_id>', currentChatId)}`
      : `${URLS.BASE_URL}${URLS.CHATGPT.chat.createOrGetChat}`;
  
    const token = user?.access_token;
    if (!token) {
      toast.error("Token introuvable. Veuillez vous reconnecter.");
      setLoading(false);
      return;
    }
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: { 'Authorization': `Bearer ${token}` },
      });
  
      // En cas de création de chat, stocker l'ID et rediriger
      if (!currentChatId) {
        const newChatId = response.headers.get('x-chat-id');
        if (newChatId) {
          localStorage.setItem('chatId', newChatId);
          navigate(`/chat/${newChatId}`);
        }
      }
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Erreur de réponse du serveur:', response.status, errorText);
        throw new Error('Erreur lors de la réponse du serveur');
      }
      
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('text/event-stream')) {
        setIsStreaming(true);
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let accumulatedContent = '';
  
        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          const chunkValue = decoder.decode(value);
          const events = chunkValue.split("\n\n");
          events.forEach(event => {
            if (event.startsWith("data: ")) {
              const data = event.replace("data: ", "").trim();
              if (data.startsWith("chatId: ")) {
                const newChatId = data.replace("chatId: ", "").trim();
                if (!chatId) {
                  localStorage.setItem('chatId', newChatId);
                  navigate(`/chat/${newChatId}`);
                }
              } else if (data === "[DONE]") {
                // Ajout du message final et nettoyage
                setFinalMessages(prev => [
                  ...prev,
                  {
                    id: Date.now(),
                    role: 'assistant',
                    type: 'html',
                    content: accumulatedContent
                  }
                ]);
                setAssistantStreamingContent('');
                setIsStreaming(false);
              } else if (data.startsWith("Erreur de streaming:")) {
                setRegenerateVisible(true);
                toast.error(data);
              } else {
                accumulatedContent += data;
                setAssistantStreamingContent(accumulatedContent);
              }
            }
          });
        }
      } else {
        // Traitement classique
        const data = await response.json();
        if (Array.isArray(data.messages)) {
          setFinalMessages(prev => [...prev, ...data.messages]);
        } else {
          toast.error('Réponse du serveur non valide.');
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du message :", error);
      toast.error("Erreur lors de l'envoi du message.");
      setRegenerateVisible(true);
    } finally {
      setLoading(false);
      setNewMessage('');
      setImages([]);
      setPdfFiles([]);
      setCsvFiles([]);
      setExcelFiles([]);
    }
  };
  
  const handleRegenerate = async () => {
    if (!lastUserMessage.trim()) {
      toast.error("Aucun message précédent à regénérer.");
      return;
    }
    setAssistantStreamingContent('');
    handleSendMessage({ preventDefault: () => {} });
  };

  const handleFileChange = (e) => {
    const newFiles = [...e.target.files];
    const imageFiles = newFiles.filter(file => file.type.startsWith('image/'));
    const pdfFiles = newFiles.filter(file => file.type === 'application/pdf');
    const csvFiles = newFiles.filter(file => file.type === 'text/csv');
    const excelFiles = newFiles.filter(file =>
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel'
    );
    setImages(imageFiles);
    setPdfFiles(pdfFiles);
    setCsvFiles(csvFiles);
    setExcelFiles(excelFiles);
  };

  const handleRemoveFile = (index, fileType) => {
    if (fileType === 'image') {
      setImages(images.filter((_, i) => i !== index));
    } else if (fileType === 'pdf') {
      setPdfFiles(pdfFiles.filter((_, i) => i !== index));
    } else if (fileType === 'csv') {
      setCsvFiles(csvFiles.filter((_, i) => i !== index));
    } else if (fileType === 'excel') {
      setExcelFiles(excelFiles.filter((_, i) => i !== index));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  return (
    <div className="chat-window">
      <Navbar />
      
      <div className="chat-body messages-list">
        {finalMessages.map((message, index) => (
          message && (
            <div key={message.id || index} className={message.role === 'user' ? 'user-message' : 'assistant-message'}>
              {message.content.includes("Image URL:") ? (
                <ImageMessage content={message.content} index={index} />
              ) : message.role === 'assistant' ? (
                <FormattedMessage content={message.content} />
              ) : (
                <UserMessage content={message.content} />
              )}
            </div>
          )
        ))}
        {isStreaming && assistantStreamingContent && (
          <div className="assistant-message streaming">
            <FormattedMessage content={assistantStreamingContent} />
          </div>
        )}
        {loading && (
          <div className="preloader-wrapper">
            <Preloader />
          </div>
        )}
        {!loading && regenerateVisible && (
          <div className="regenerate-container">
            <button onClick={handleRegenerate}>Regénérer la réponse</button>
          </div>
        )}
      </div>

      <div className="chat-footer">
        <form className={`chat-input ${finalMessages.length === 0 ? 'empty' : 'active'}`} onSubmit={handleSendMessage}>
          <button type="button" className="upload-btn" onClick={handleUploadClick}>
            <FaPaperclip />
          </button>
          <input
            accept="image/*,application/pdf,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
            id="upload-input"
            type="file"
            multiple
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          <input
            type="text"
            value={newMessage}
            onKeyDown={handleKeyDown}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Tapez votre message ici..."
            disabled={loading}
          />
          <button type="submit" className="send-btn" disabled={loading || (!newMessage.trim() && images.length === 0)}>
            {loading ? "Envoi..." : <FaPaperPlane />}
          </button>
        </form>
      </div>

      {previews.length > 0 && (
        <div className="image-preview">
          {previews.map((preview, index) => (
            <div key={index} className="image-preview">
              {preview.endsWith('.pdf') ? (
                <div className="image-preview">
                  <p>{preview}</p>
                  <button onClick={() => handleRemoveFile(index, 'pdf')}>
                    <FaTimes />
                  </button>
                </div>
              ) : preview.endsWith('.csv') ? (
                <div className="image-preview">
                  <p>{preview}</p>
                  <button onClick={() => handleRemoveFile(index, 'csv')}>
                    <FaTimes />
                  </button>
                </div>
              ) : preview.endsWith('.xlsx') ? (
                <div className="image-preview">
                  <p>{preview}</p>
                  <button onClick={() => handleRemoveFile(index, 'excel')}>
                    <FaTimes />
                  </button>
                </div>
              ) : (
                <div className="image-preview">
                  <img src={preview} alt="Preview" />
                  <button onClick={() => handleRemoveFile(index, 'image')}>
                    <FaTimes />
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChatWindow;
